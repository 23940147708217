<template>
  <div>
    <div class="navi-link" @click.stop="dialog = true">
      <span class="navi-icon">
        <i class="flaticon-edit-1 text-warning"></i>
      </span>
      <span class="navi-text">{{ $t('ACTION.UPDATE') }} </span>
    </div>
    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title class="headline">Hodim bo'lim +</v-card-title>

        <v-card-text>
          <v-text-field
            label="Bo'lim nomi "
            v-model="name"
            dense
            outlined
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="dialog = false"> Bekor qilish </v-btn>

          <v-btn color="primary" @click="submit"> Yuborish </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      name: ''
    }
  },
  created() {
    this.name = this.staffData.name
  },
  methods: {
    submit() {
      const data = {
        name: this.name
      }
      this.$store.dispatch('staffDepartmentsUpdate', {
        id: this.staffData.id,
        data
      })
      this.dialog = false
    }
  },
  props: {
    staffData: {
      type: Object
    }
  }
}
</script>

<style scoped>
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem;
}
</style>
