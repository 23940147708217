<template>
  <div>
    <v-btn color="primary" small @click.stop="dialog = true">
      {{ $t('MENU.ENTERPRICE.STAFF.DEPARTMENTS') }} +
    </v-btn>

    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title class="headline">Hodim bo'lim</v-card-title>

        <v-card-text>
          <v-text-field
            label="Bo'lim nomi "
            v-model="name"
            dense
            outlined
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            :disabled="newDepartmentLoading"
            @click="dialog = false"
          >
            Bekor qilish
          </v-btn>

          <v-btn
            color="primary"
            :disabled="name == '' || newDepartmentLoading"
            @click="submit"
          >
            <i v-if="newDepartmentLoading" class="el-icon-loading"></i> Yuborish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newDepartmentLoading: false,
      dialog: false,
      name: ''
    }
  },
  methods: {
    submit() {
      const data = {
        name: this.name
      }
      this.newDepartmentLoading = true
      this.$store
        .dispatch('staffDepartmentsCreate', data)
        .then(() => {
          this.newDepartmentLoading = false
          this.name = ''
          this.dialog = false
        })
        .catch((err) => {
          this.newDepartmentLoading = false
          console.error(err)
        })
    }
  }
}
</script>

<style></style>
